import { StaticImage } from "gatsby-plugin-image"
import React from "react"
import Card from "./Card"


const Clients = () => {
 

  return (
    <>
      <div className="flex flex-col py-16 lg:py-32 text-black font-semibold">
        <div className="flex justify-center items-center">
          <h1 className="text-6xl py-4">Our Clients</h1>
        </div>

        <div className="flex flex-col pt-8 md:flex-row items-center justify-between">
          <div className="w-48 ">
            <StaticImage
              src="../images/clients/cocacola.png"
              placeholder="blurred"
              className=" mr-6 "
              alt="Lagos Danfo Media Seal WPI"
            />
          </div>

          <div className="w-48 ">
            <StaticImage
              src="../images/webimages/5e621fcbdf8a7_1583488971.png"
              placeholder="blurred"
              className="mr-6 p-2 "
              alt="Lagos Danfo Media Seal WPI"
              style={{
                maxWidth: 120,
              }}
            />
          </div>

          <div className="w-48 ">
            <StaticImage
              src="../images/clients/samsung.png"
              placeholder="blurred"
              className=" mr-6 p-2"
              alt="Lagos Danfo Media Seal WPI"
            />{" "}
          </div>

          <div className="w-48 ">
            <StaticImage
              src="../images/clients/qatar.png"
              placeholder="blurred"
              className=" mr-6 p-2"
              alt="Lagos Danfo Media Seal WPI"
            />{" "}
          </div>

          <div className="w-48 flex items-center justify-center">
            <StaticImage
              src="../images/clients/La-casera.jpeg"
              placeholder="blurred"
              className=" mr-6 p-2"
              alt="Lagos Danfo Media Seal WPI"
              style={{
                width: 120,
              }}
            />{" "}
          </div>

          <div className="w-48 flex items-center justify-center">
            <StaticImage
              src="../images/webimages/nestle-9-logo-png-transparent.png"
              placeholder="blurred"
              className=" mr-6 p-2 flex items-center justify-center"
              alt="Lagos Danfo Media Seal WPI"
              style={{
                width: 100,
              }}
            />
          </div>

          <div className="w-48 ">
            <StaticImage
              src="../images/clients/guinness.png"
              placeholder="blurred"
              className=" mr-6 p-2"
              alt="Lagos Danfo Media Seal WPI"
            />{" "}
          </div>
        </div>

        <div className="flex flex-col md:flex-row items-center justify-between">
          <div className="w-48 ">
            <StaticImage
              src="../images/clients/dangote.png"
              placeholder="blurred"
              className=" mr-6 p-2"
              alt="Lagos Danfo Media Seal WPI"
            />
          </div>

          <div className="w-48 ">
            <StaticImage
              src="../images/clients/etisalat.png"
              placeholder="blurred"
              className=" mr-6 p-2"
              alt="Lagos Danfo Media Seal WPI"
            />{" "}
          </div>

          <div className="w-48 flex items-center justify-center">
            <StaticImage
              src="../images/clients/nb.jpeg"
              placeholder="blurred"
              className=" mr-6 p-2"
              alt="Lagos Danfo Media Seal WPI"
              // width={120}
              style={{
                width: 120,
              }}
            />
          </div>

          <div className="w-48">
            <StaticImage
              src="../images/clients/chi.png"
              placeholder="blurred"
              className=" mr-6 p-2"
              alt="Lagos Danfo Media Seal WPI"
            />
          </div>

          <div className="w-48 flex items-center justify-center">
            <StaticImage
              src="../images/clients/nile.jpeg"
              placeholder="blurred"
              className=" mr-6 p-2"
              alt="Lagos Danfo Media Seal WPI"
              style={{
                width: 90,
              }}
            />{" "}
          </div>

          <div className="w-48">
            <StaticImage
              src="../images/clients/emirate.png"
              placeholder="blurred"
              className=" mr-6 p-2"
              alt="Lagos Danfo Media Seal WPI"
            />{" "}
          </div>

          <div className="w-48">
            <StaticImage
              src="../images/pg.png"
              placeholder="blurred"
              className=" mr-6 p-2"
              alt="Lagos Danfo Media Seal WPI"
            />{" "}
          </div>
        </div>

        {/* <div className="flex flex-col md:flex-row items-center justify-between">
          <div className="w-48 grayscaleImg">
            <StaticImage
              src="../images/clients/nb.jpeg"
              placeholder="blurred"
              className=" mr-6 p-2"
              alt="Lagos Danfo Media Seal WPI"
              // width={120}
            />
          </div>

          <div className="w-48 grayscaleImg">
            <StaticImage
              src="../images/clients/cocacola.png"
              placeholder="blurred"
              className=" mr-6 "
              alt="Lagos Danfo Media Seal WPI"
            />
          </div>

          <div className="w-48 grayscaleImg">
            <StaticImage
              src="../images/clients/dangote.png"
              placeholder="blurred"
              className=" mr-6 p-2"
              alt="Lagos Danfo Media Seal WPI"
            />
          </div>

          <div className="w-48 grayscaleImg">
            <StaticImage
              src="../images/clients/huawei.png"
              placeholder="blurred"
              className=" mr-6 p-2"
              alt="Lagos Danfo Media Seal WPI"
            />{" "}
          </div>

          <div className="w-48 grayscaleImg">
            <StaticImage
              src="../images/clients/emirate.png"
              placeholder="blurred"
              className=" mr-6 p-2"
              alt="Lagos Danfo Media Seal WPI"
            />{" "}
          </div>

          <div className="w-48 grayscaleImg">
            <StaticImage
              src="../images/clients/qatar.png"
              placeholder="blurred"
              className=" mr-6 p-2"
              alt="Lagos Danfo Media Seal WPI"
            />{" "}
          </div>

          <div className="w-48 grayscaleImg">
            <StaticImage
              src="../images/clients/samsung.png"
              placeholder="blurred"
              className=" mr-6 p-2"
              alt="Lagos Danfo Media Seal WPI"
            />{" "}
          </div>
        </div> */}
      </div>
    </>
  )
}

export default Clients
